<template>
  <div>
    <div>
      <a
        :href="'https://www.instagram.com/' + config.VUE_APP_INSTA_NAME + '/'"
        target="_blank"
        rel="noopener"
      >
        <h2>
          <font-awesome-icon
            id="insta"
            class="icon fa-gradient"
            :icon="['fab', 'instagram-square']"
          />
          <span> {{ config.VUE_APP_INSTA_NAME }}</span>
        </h2>
      </a>
    </div>
    <b-row>
      <b-col
        lg="4"
        md="3"
        v-for="(insta, index) in insta.data.slice(0, 9)"
        :key="index"
        class="instaHeight"
      >
        <a
          :href="insta.permalink"
          target="_blank"
          rel="noopener"
          class="instaPic"
        >
          <template v-if="insta.media_type === 'VIDEO'">
            <div
              class="instaInner-pic"
              :style="'background-image:url(' + insta.thumbnail_url + ');'"
            >
              <font-awesome-icon
                id="fb"
                class="playCircle"
                :icon="['fa', 'play-circle']"
              />
            </div>
          </template>
          <template v-else>
            <div
              class="instaInner-pic"
              :style="'background-image: url(' + insta.media_url + ');'"
            ></div>
          </template>
        </a>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import apiCall from "@/services/api.js";
export default {
  name: "instafeed",
  props: ["config"],
  data() {
    return {
      insta: [],
    };
  },
  created() {
    const call = process.env.VUE_APP_INSTAGRAM_URL;
    apiCall
      .youTubeInsta(call)
      .then(({ data }) => {
        this.insta = data;
        this.loading = false;
      })
      .catch((error) => {
        console.log(error);
        throw error;
      });
  },
};
</script>

<style scoped>
.title {
  font-size: 25px;
  font-weight: 400;
  font-family: Helvetica, Roboto, "Segoe UI", Calibri, sans-serif;
}
.playCircle {
  font-size: 100px;
  color: #ffffff;
  margin-top: 66px;
}
.instaInner-pic {
  background-position: left;
  background-repeat: no-repeat;
  background-size: cover;
  text-align: center;
  background-color: #263056;
}
a.instaPic {
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  display: grid;
}
.instaHeight {
  padding: 2px;
}
.instaHeight.col-lg-4 {
  height: 120px;
  overflow: hidden;
}
.fa-gradient {
  color: #333;
}
@media only screen and (max-width: 2560px) {
  .instaHeight.col-lg-4 {
    height: 250px;
  }
}
@media only screen and (max-width: 1440px) {
  .instaHeight.col-lg-4 {
    height: 185px;
  }
  .playCircle {
    font-size: 100px;
    color: #ffffff;
    margin-top: 38px;
  }
}
@media only screen and (max-width: 1024px) {
  .instaHeight.col-lg-4 {
    height: 150px;
  }
  .playCircle {
    font-size: 70px;
    color: #ffffff;
    margin-top: 37px;
  }
}

@media only screen and (max-width: 480px) {
  .instaHeight.col-lg-4 {
    height: 300px;
  }
  .playCircle {
    margin-top: 100px;
  }
}
</style>
