<template>
  <div>
    <b-row class="squareGrid">
      <b-col lg="6" md="12" clos="12" class="squareColLeft"></b-col>
      <b-col id="blueGrad" lg="6" md="12" cols="12" class="squareCol">
        <div class="container">
          <div class="gradText">
            <h3 class="gridTitle" style="color:white;">
              {{ data.blue_title }}
            </h3>
            <br />
            <p>
              <span v-html="data.blue_text"></span>
              <a href="/contact-us" role="button">
                <button id="homeButton">JOIN TODAY</button>
              </a>
            </p>
          </div>
        </div>
      </b-col>
    </b-row>
    <b-row class="squareGrid">
      <b-col
        lg="6"
        md="12"
        cols="12"
        order="2"
        order-lg="2"
        order-md="1"
        class="squareColRight"
      ></b-col>
      <b-col
        id="redGrad"
        lg="6"
        md="12"
        cols="12"
        order="1"
        order-lg="1"
        order-md="2"
      >
        <div class="container">
          <div class="gradText">
            <h3 class="gridTitle" style="color:white;">{{ data.red_title }}</h3>
            <br />
            <span v-html="data.red_text"></span>
            <br />
            <div id="appsStore">
              <a :href="config.VUE_APP_APPLE_STORE">
                <img
                  class="app"
                  src="https://ocs-sport.ams3.cdn.digitaloceanspaces.com/tartanprotour/2021/03/Mask-Group-5.png"
                />
              </a>
              <a :href="config.VUE_APP_GOOGLE_PLAY">
                <img
                  class="app"
                  src="https://ocs-sport.ams3.cdn.digitaloceanspaces.com/tartanprotour/2021/03/Mask-Group-4.png"
                />
              </a>
            </div>
          </div>
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import apiCall from "@/services/api.js";
export default {
  name: "cubes",
  props: ["config"],
  data() {
    return {
      data: [],
    };
  },
  created() {
    const json = "wp/v2/pages/8";
    apiCall
      .wpInfo(json)
      .then(({ data }) => {
        this.data = data.acf;
        this.loading = false;
      })
      .catch((error) => {
        console.log(error);
        throw error;
      });
  },
};
</script>

<style scoped>
img.app {
  padding-right: 15px;
  width: 200px !important;
}
#homeButton:hover {
  background-color: #c04358;
  color: white;
  border-color: #c04358;
  border-style: solid;
  border-width: thin;
  padding: 10px 50px 10px 50px;
}
.squareColLeft {
  background-image: url(https://ocs-sport.ams3.cdn.digitaloceanspaces.com/tartanprotour/2021/03/Group-21-1-1.png);
  background-repeat: no-repeat;
  background-size: cover;
}
#blueGrad {
  background: radial-gradient(#204e7c, #242739);
  color: white;
}
#redGrad {
  background: radial-gradient(#f27177, #cc444a);
  color: white;
}
.squareColRight {
  background-image: url(https://ocs-sport.ams3.cdn.digitaloceanspaces.com/tartanprotour/2021/03/Pollok-Tartan-Pro-Tour-57.png);
  background-repeat: no-repeat;
  background-size: cover;
}
.gridTitle {
  font-size: calc(1.2vw + 1.7vh);
}
.GridSmall {
  font-weight: 100;
}
.gradText {
  padding-top: calc(3vw + 3vh);
  padding-left: calc(3vw + 3vh);
  padding-right: calc(3vw + 3vh);
  padding-bottom: calc(3vw + 3vh);
}
#homeButton {
  background-color: transparent;
  color: white;
  border-color: white;
  border-style: solid;
  border-width: thin;
  padding: 10px 50px 10px 50px;
}
@media only screen and (max-width: 990px) {
  .squareGrid {
    display: block;
  }
  .squareColLeft {
    height: 800px;
  }
  .squareColRight {
    height: 800px;
  }
}
@media only screen and (max-width: 480px) {
  .squareColLeft {
    height: 500px;
  }
  .squareColRight {
    height: 500px;
  }
}
</style>
