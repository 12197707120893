<template>
  <div>
    <a class="twitter-timeline" data-height="842" :href="'https://twitter.com/' + config.VUE_APP_TWITTER_NAME + '?ref_src=twsrc%5Etfw'">Tweets by tartanprotour</a> <script type="application/javascript" async src="https://platform.twitter.com/widgets.js" charset="utf-8"></script>
  </div>
</template>

<script>
export default {
  name: "twitterFeed",
  props: ["config"]
};
</script>

<style scoped>
#twitter {
  width: 22%;
}
.timeline-Tweet-media {
  display: none !important;
}
</style>
