<template>
  <div id="reportTable">
    <!-- <div class="title" :class="{ centerTitle: home === true }">
      <h3 class="fullnameandTitle">{{ data.full_name }} - {{ title }}</h3>
    </div> -->

    <table>
      <thead></thead>
      <tbody>
        <template v-if="tablePick === 'Entries'">
          <Entries
            :data="data"
            :previousData="previousData"
            :home="home"
            :title="title"
            :config="config"
            :team="team"
            :match="data.match_play"
            :code="code"
            :season="season"
            :show_info_web="show_info_web"
            :hide_score_card="hide_score_card"
          />
        </template>
        <template v-if="tablePick === 'Draw'">
          <Draw
            :data="data"
            :previousData="previousData"
            :home="home"
            :title="title"
            :config="config"
            :team="team"
            :match="data.match_play"
            :code="code"
            :season="season"
            :show_info_web="show_info_web"
            :hide_score_card="hide_score_card"
          />
        </template>
        <template v-if="tablePick === 'Score' || tablePick === 'Live'">
          <Score
            :tab="tab"
            :data="data"
            :title="title"
            :home="home"
            :config="config"
            :team="team"
            :match="match"
            :season="season"
            :code="code"
            :scoreFound="scoreFound"
            :showGender="showGender"
            :is_qualifier="is_qualifier"
            :stableford="stableford"
            :show_info_web="show_info_web"
            :hide_score_card="hide_score_card"
          />
        </template>
        <template v-if="tablePick === 'Final'">
          <Results
            :data="data"
            :dataShowOOM="data.show_winnings"
            :dataHavePoints="data.have_points"
            :dataShowPointsName="data.show_points_name"
            :dataHaveOom="data.have_oom"
            :title="title"
            :home="home"
            :config="config"
            :team="team"
            :match="match"
            :season="season"
            :code="code"
            :showGender="showGender"
            :is_qualifier="is_qualifier"
            :stableford="stableford"
            :roundsplayed="data.rounds_played"
            :show_info_web="show_info_web"
            :hide_score_card="hide_score_card"
          />
        </template>
        <template v-for="(tabledata, index) in Eds">
          <tr @click.stop="rowClicked(index)" :key="index"></tr>
          <tr
            @click.stop="rowClicked(rowClicked === -1)"
            v-if="index === clickedRow"
            class="tr-kids"
            :key="index + Math.random()"
          >
            <td id="reportCard" colspan="11">
              <PlayerReportCard
                :data="playerCardData"
                :config="config"
                :actualRound="data.pft_round"
                :roundsPlayed="data.pft_round"
                :playerData="playerData"
                :playerDataTeam="playerDataTeam"
                :showGender="showGender"
                :season="season"
                :show_info_web="show_info_web"
                :hide_score_card="hide_score_card"
              />
            </td>
          </tr>
        </template>
        <template>
          <div class="LiveScoreMess" v-if="data.live_scoring_mess">
            <p class="LiveScoreMessPara">{{ data.live_scoring_mess }}</p>
          </div>
        </template>
      </tbody>
    </table>
  </div>
</template>

<script>
// import apiCall from "@/services/api.js";
import {
  Entries,
  Draw,
  Score,
  PlayerReportCard,
  Results
} from "@ocs-software/vue-ocs";
export default {
  name: "reportTable",
  props: [
    "tab",
    "data",
    "previousData",
    "home",
    "title",
    "season",
    "code",
    "tour",
    "team",
    "config",
    "match",
    "dataShowOOM",
    "scoreFound",
    "showGender",
    "is_qualifier",
    "stableford",
    "show_info_web",
    "hide_score_card"
  ],
  components: { PlayerReportCard, Entries, Draw, Score, Results },
  data() {
    return {
      compare: [],
      up: "up",
      down: "down",
      scores: [],
      clickedRow: -1,
      cardID: [],
      playerCardData: [],
      win: "win"
    };
  },
  methods: {
    range: function(start, end) {
      if (this.roundsPlayed === 1) return this.roundsPlayed;
      else
        return Array(end - start + 1)
          .fill()
          .map((_, idx) => start + idx);
    },
    rowClicked: function(index) {
      if (this.clickedRow !== index) this.clickedRow = index;
      else this.clickedRow = -1;
    },
    playerCard: function(playerNo) {
      var playerData = {};
      console.log("playerData");
      console.log(playerData);
      var cardID = playerNo;
      console.log("cardID");
      console.log(cardID);
      for (var i of this.data.scores.scores_entry) {
        console.log("in scores");
        if (i.member_no == playerNo) {
          this.playerData = i;
          console.log("playerData 384");
          console.log(this.playerData);
          break;
        }
      }
    }
  },
  computed: {
    tablePick: function(picked) {
      if (this.title.includes("Tournament")) picked = "Entries";
      else if (this.title.includes("Draw")) picked = "Draw";
      else if (
        this.title.includes("Scores") ||
        this.title.includes("Scoreboard")
      )
        picked = "Score";
      else if (
        this.title.includes("Final") ||
        this.title.includes("Statistics")
      )
        picked = "Final";
      else if (this.title.includes("Live")) picked = "Live";
      return picked;
    },
    roundsPlayed() {
      return parseInt(this.data.rounds_played);
    },
    Eds(reportData) {
      if (this.home === false) {
        if (this.title.includes("Tournament"))
          reportData = this.data.entries.entries_entry;
        else if (this.title.includes("Draw"))
          reportData = this.data.draw.draw_entry;
        else if (
          this.title.includes("Scores") ||
          this.title.includes("Scoreboard") ||
          this.title.includes("Live")
        )
          reportData = this.data.scores.scores_entry;
        else if (
          this.title.includes("Final") ||
          this.title.includes("Statistics")
        )
          reportData = this.data.result.result_entry;
        return reportData;
      } else {
        if (this.title === "Tournament Entries")
          reportData = this.data.entries.entries_entry.slice(0, 4);
        else if (this.title.includes("Draw"))
          reportData = this.data.draw.draw_entry.slice(0, 4);
        else if (
          this.title.includes("Scores") ||
          this.title.includes("Scoreboard") ||
          this.title.includes("Live")
        )
          reportData = this.data.scores.scores_entry.slice(0, 4);
        else if (
          this.title.includes("Final") ||
          this.title.includes("Statistics")
        )
          reportData = this.data.result.result_entry.slice(0, 4);
        return reportData;
      }
    }
  },
  watch: {
    Eds(newValue, oldValue) {
      newValue.forEach((value1, key1) => {
        oldValue.forEach((value2, key2) => {
          if (value1.id === value2.id) {
            if (key1 < key2) {
              value1.posChang = "up";
            } else if (key1 > key2) {
              value1.posChang = "down";
            }
          }
        });
      });

      console.log(newValue);
    },
    immediate: true,
    deep: true
  },
  filters: {
    lowercase: function(value) {
      if (!value) {
        return "";
      }
      return value.toLowerCase() + ".svg";
    }
  }
};
</script>
<style scoped>
p.LiveScoreMessPara {
  color: #fff;
  padding-top: 15px;
}
.LiveScoreMess {
  background: red;
  text-align: center;
  position: fixed;
  bottom: 0;
  width: 100%;
  z-index: 99;
  margin: auto;
  left: 0;
  right: 0;
}
td.rightScore {
  text-align: right;
}
td.rightName {
  text-align: center;
}
th.rightScore {
  text-align: right;
}
th.rightName {
  text-align: center;
}
span.drawMatch {
  position: relative;
  top: -5px;
}
tr > td.drawTd {
  padding-bottom: 0px;
}
td.namePlayer {
  text-align: left !important;
}
th.namePlayer {
  text-align: left !important;
}
a.drawNameColor {
  color: #000;
}
a.playerName {
  color: #000;
}
h3.fullnameandTitle {
  font-size: 12pt !important;
  text-align: left;
  padding-bottom: 22px;
}
td.catD {
  background: #1b2437;
  color: #fff;
}
.drawPos {
  max-width: 100% !important;
  text-align: -webkit-center !important;
  margin-top: 0px !important;
}
::v-deep .title {
  text-align: left;
  background-color: #1a2337;
  color: white;
  padding-top: 1em;
  padding-bottom: 1em;
  margin-left: 0px !important;
  margin-right: 0px !important;
  text-align: left;
}
.centerTitle > h3 {
  text-align: center !important;
}
.title > h3 {
  padding-top: 8px;
  margin-bottom: 0px;
  font-size: 13pt !important;
  font-weight: 400;
  padding-bottom: 2em;
}
.title > p {
  font-size: 16px !important;
  margin-bottom: 0;
}
th {
  position: sticky;
  top: 0;
  background-color: #1a2337;
  color: white !important;
  padding-bottom: 10px;
  font-size: 10pt;
  font-weight: normal;
}
table {
  width: 100%;
}
thead > tr {
  color: white;
}
td:first-child {
  text-align: center;
}
th:first-child {
  text-align: center;
}
th:nth-child(3) {
  text-align: center;
}
td:nth-child(3) {
  text-align: center;
}
.odd {
  background-color: white !important;
}
.even {
  /* background-color: rgba(0,0,0,.05); */
  background: #e8e8e8;
}
td {
  /* padding: .75rem; */
  padding: 8px;
  vertical-align: top;
  border-top: 0px solid #dee2e6;
  font-size: 10pt;
}
tr:nth-child(even) {
  background: rgba(0, 0, 0, 0.05);
}
td.posnum {
  padding: 5px;
  padding-top: 4px;
  padding-bottom: 4px;
  display: block;
  max-width: 58px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 8px;
  margin-bottom: 0px;
}
td.posnum > span {
  padding: 5px;
  display: block;
  position: relative;
  top: -5px;
  /* max-width: 58px; */
}
.noPos > span {
  color: transparent;
  padding: 5px;
  display: block;
  max-width: 58px;
}
.flag {
  width: 30px;
  /* box-shadow: 0 0 3px #555; */
}
.up {
  color: #a52a2a;
}
.down {
  color: #1e90ff;
}
.text-down {
  background: transparent;
  text-shadow: 0 0 0 0 rgba(255, 82, 82, 1);
  animation: glow-red 2s infinite;
  animation-iteration-count: 6;
}

@keyframes glow-red {
  to {
    text-shadow: 0 0 10px rgba(255, 82, 82, 0.7);
  }
}

.text-up {
  background: transparent;
  text-shadow: 0 0 0 0 rgba(51, 217, 178, 1);
  animation: glow-green 2s infinite;
  animation-iteration-count: 6;
}

@keyframes glow-green {
  to {
    text-shadow: 0 0 10px rgba(51, 217, 178, 0.7);
  }
}
.cut {
  padding-top: 6px !important;
  padding-right: 6px !important;
  padding-bottom: 6px !important;
  font-size: 19px !important;
  vertical-align: middle;
  padding-left: 8px !important;
  background: #aa0003;
  color: white;
}

.win {
  background-color: #1a2337 !important;
  color: #fff;
}

@media only screen and (max-width: 480px) {
  th {
    position: sticky;
    top: 0;
    background-color: #1a2337;
    color: white !important;
    padding-bottom: 10px;
    font-size: 10pt;
  }
  .mobHide {
    display: none;
  }
}
</style>

<style>
td.rightScore {
  text-align: right;
}
td.rightName {
  text-align: center;
}
th.rightScore {
  text-align: right;
}
th.rightName {
  text-align: center;
}
span.drawMatch {
  position: relative;
  top: -5px;
}
tr > td.drawTd {
  padding-bottom: 0px;
}
td.namePlayer {
  text-align: left !important;
}
th.namePlayer {
  text-align: left !important;
}
a.drawNameColor {
  color: #000;
}
a.playerName {
  color: #000;
}
h3.fullnameandTitle {
  font-size: 12pt !important;
  text-align: left;
  padding-bottom: 22px;
}
td.catD {
  background: #1b2437;
  color: #fff;
}
.drawPos {
  max-width: 100% !important;
  text-align: -webkit-center !important;
  margin-top: 0px !important;
}
::v-deep .title {
  text-align: left;
  background-color: #1a2337;
  color: white;
  padding-top: 1em;
}
.centerTitle > h3 {
  text-align: center !important;
}
.title > h3 {
  padding-top: 8px;
  margin-bottom: 0px;
  font-size: 13pt !important;
  font-weight: 400;
  padding-bottom: 2em;
}
.title > p {
  font-size: 16px !important;
  margin-bottom: 0;
}
th {
  position: sticky;
  top: 0;
  background-color: #1a2337;
  color: white !important;
  padding-bottom: 10px;
  font-size: 10pt;
  font-weight: normal;
}
table {
  width: 100%;
}
thead > tr {
  color: white;
}
td:first-child {
  text-align: center;
}
th:first-child {
  text-align: center;
}
th:nth-child(3) {
  text-align: center;
}
td:nth-child(3) {
  text-align: center;
}
.odd {
  background-color: white !important;
}
.even {
  /* background-color: rgba(0,0,0,.05); */
  background: #e8e8e8;
}
td {
  /* padding: .75rem; */
  padding: 11px;
  vertical-align: top;
  border-top: 0px solid #dee2e6;
  font-size: 10pt;
}
tr:nth-child(even) {
  background: rgba(0, 0, 0, 0.05);
}
td.posnum {
  padding: 5px;
  padding-top: 4px;
  padding-bottom: 4px;
  display: block;
  max-width: 58px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 8px;
  margin-bottom: 0px;
}
td.posnum > span {
  padding: 5px;
  display: block;
  position: relative;
  top: -5px;
  /* max-width: 58px; */
}
.noPos > span {
  color: transparent;
  padding: 5px;
  display: block;
  max-width: 58px;
}
.flag {
  width: 30px;
  /* box-shadow: 0 0 3px #555; */
}
.up {
  color: #a52a2a;
}
.down {
  color: #1e90ff;
}
.text-down {
  background: transparent;
  text-shadow: 0 0 0 0 rgba(255, 82, 82, 1);
  animation: glow-red 2s infinite;
  animation-iteration-count: 6;
}

@keyframes glow-red {
  to {
    text-shadow: 0 0 10px rgba(255, 82, 82, 0.7);
  }
}

.text-up {
  background: transparent;
  text-shadow: 0 0 0 0 rgba(51, 217, 178, 1);
  animation: glow-green 2s infinite;
  animation-iteration-count: 6;
}

@keyframes glow-green {
  to {
    text-shadow: 0 0 10px rgba(51, 217, 178, 0.7);
  }
}
.cut {
  padding-top: 6px !important;
  padding-right: 6px !important;
  padding-bottom: 6px !important;
  font-size: 19px !important;
  vertical-align: middle;
  padding-left: 8px !important;
  background: #aa0003;
  color: white;
}

.win {
  background-color: #1a2337 !important;
  color: #fff;
}

@media only screen and (max-width: 480px) {
  th {
    position: sticky;
    top: 0;
    background-color: #1a2337;
    color: white !important;
    padding-bottom: 10px;
    font-size: 10pt;
  }
  .mobHide {
    display: none;
  }
}
</style>
<style scoped src="@/css/entries.css"></style>
<style scoped src="@/css/draw.css"></style>
<style src="@/css/scoresOnly.css"></style>
<style src="@/css/teamscoresOnly.css"></style>
<style src="@/css/hbh.css"></style>
<style src="@/css/results.css"></style>
<style src="@/css/orginalReportCard.css"></style>
<style src="@/css/playerReportCard.css"></style>