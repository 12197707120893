<template>
  <div class="footer">
    <div v-if="white === true" class="white">
      <b-row class="FooterSponsorsSup" >
        <b-col lg='12' md='12'>
          <b-row>
            <b-col>
              <h2 class="title" style="padding-top: 10px; padding-bottom:10px">
                Sponsors / Partners
              </h2>
            </b-col>
          </b-row>
          <b-row>
            <b-col
            lg='3' md='6' cols='6'
              :class="{ hide: logo.acf.supplier, show: logo.acf.partner }"
              class="logoPadding"
              v-for="(logo, index) in footW"
              :key="index"
            >
              <a :href="logo.acf.url" target="_blank">
                <img class="logos" :src="logo.acf.image" />
              </a>
            </b-col>
          </b-row>
        </b-col>
        <!-- <b-col lg='6' md='12' cols='12'>
          <b-row>
            <b-col>
              <h2 class="title suppliersText">
                Suppliers
              </h2>
            </b-col>
          </b-row>
          <b-row>
            <b-col
            lg='3' md='6' cols='6'
              :class="{ hide: logo.acf.partner, show: logo.acf.supplier }"
              class="logoPadding suppliersLogo"
              v-for="(logo, index) in footW"
              :key="index"
            >
              <a :href="logo.acf.url" target="_blank">
                <img class="logos" :src="logo.acf.image" />
              </a>
            </b-col>
          </b-row>
        </b-col> -->
      </b-row>
      <b-row @mouseover="hover = true" @mouseleave="hover = false"  :class="{contactHide : $route.name === 'contact'}" class='desktop' style="display:block">
        <a href="/contact-us">
          <img
            class="maleFooter"
            v-if="hover"
            src="https://ocs-sport.ams3.cdn.digitaloceanspaces.com/tartanprotour/2021/03/banner_male_active.png"
          />
          <img
            class="maleFooter"
            v-else
            src="https://ocs-sport.ams3.cdn.digitaloceanspaces.com/tartanprotour/2021/03/banner_male_inactive.png"
          />
        </a>
      </b-row>
      <b-row @mouseover="hover = true" @mouseleave="hover = false"  :class="{contactHide : $route.name === 'contact'}" class='mobile'>
        <a href="/contact-us">
          <img
            class="maleFooter"
            v-if="hover"
            src="https://ocs-sport.ams3.cdn.digitaloceanspaces.com/tartanprotour/2021/03/banner_male_inactive_mobile.png"
          />
          <img
            class="maleFooter"
            v-else
            src="https://ocs-sport.ams3.cdn.digitaloceanspaces.com/tartanprotour/2021/03/banner_male_active_mobile.png"
          />
        </a>
      </b-row>
    </div>
    <div v-if="white === false" class="grey">
      <b-row class="FooterSponsorsSup" :class="{contactMarg: this.$route.name === 'contact'}">
        <b-col lg='12' md='12'>
          <b-row>
            <b-col>
              <h2 class="title" style="padding-top: 10px; padding-bottom:10px">
                Sponsors / Partners
              </h2>
            </b-col>
          </b-row>
          <b-row>
            <b-col
            lg='3' md='6' cols='6'
              :class="{ hide: logo.acf.supplier, show: logo.acf.partner }"
              class="logoPadding"
              v-for="(logo, index) in footG"
              :key="index"
            >
              <a :href="logo.acf.url" target="_blank">
                <img class="logos" :src="logo.acf.image" />
              </a>
            </b-col>
          </b-row>
        </b-col>
        <!-- <b-col lg=6 md='12'>
          <b-row>
            <b-col>
              <h2 class="title suppliersText">
                Suppliers
              </h2>
            </b-col>
          </b-row>
          <b-row>
            <b-col
            lg='3' md='6' cols='6'
              :class="{ hide: logo.acf.partner, show: logo.acf.supplier }"
              class="logoPadding suppliersLogo"
              v-for="(logo, index) in footG"
              :key="index"
            >
              <a :href="logo.acf.url" target="_blank">
                <img class="logos" :src="logo.acf.image" />
              </a>
            </b-col>
          </b-row>
        </b-col> -->
      </b-row>
      <b-row @mouseover="hover = true" @mouseleave="hover = false"  :class="{contactHide : $route.name === 'contact'}" class='desktop' style="display:block">
        <a href="/contact-us">
          <img
            class="maleFooter"
            v-if="hover"
            src="https://ocs-sport.ams3.cdn.digitaloceanspaces.com/tartanprotour/2021/03/banner_female_active.png"
          />
          <img
            class="maleFooter"
            v-else
            src="https://ocs-sport.ams3.cdn.digitaloceanspaces.com/tartanprotour/2021/03/banner_female_inactive.png"
          />
        </a>
      </b-row>
            <b-row @mouseover="hover = true" @mouseleave="hover = false"  :class="{contactHide : $route.name === 'contact'}" class='mobile'>
        <a href="/contact-us">
          <img
            class="maleFooter"
            v-if="hover"
            src="https://ocs-sport.ams3.cdn.digitaloceanspaces.com/tartanprotour/2021/03/banner_female_active_mobile-copy.png"
          />
          <img
            class="maleFooter"
            v-else
            src="https://ocs-sport.ams3.cdn.digitaloceanspaces.com/tartanprotour/2021/03/banner_female_inactive_mobile.png"
          />
        </a>
      </b-row>
    </div>
    <div v-if="contactF === true" class="contact">
      <b-row class="container FooterSponsorsSup">
        <b-col md='12'>
          <b-row>
            <b-col>
              <h2 class="title" style="padding-top: 10px; padding-bottom:10px">
                Sponsors / Partners
              </h2>
            </b-col>
          </b-row>
          <b-row>
            <b-col
            lg='3' md='6'
              :class="{ hide: logo.acf.supplier, show: logo.acf.partner }"
              class="logoPadding"
              v-for="(logo, index) in footW"
              :key="index"
            >
              <a :href="logo.acf.url" target="_blank">
                <img class="logos" :src="logo.acf.image" />
              </a>
            </b-col>
          </b-row>
        </b-col>
        <!-- <b-col md='12'>
          <b-row>
            <b-col>
              <h2 class="title suppliersText">
                Suppliers
              </h2>
            </b-col>
          </b-row>
          <b-row>
            <b-col
            lg='3' md='6'
              :class="{ hide: logo.acf.partner, show: logo.acf.supplier }"
              class="logoPadding suppliersLogo"
              v-for="(logo, index) in footW"
              :key="index"
            >
              <a :href="logo.acf.url" target="_blank">
                <img class="logos" :src="logo.acf.image" />
              </a>
            </b-col>
          </b-row>
        </b-col> -->
      </b-row>
    </div>
      <div id="social" :class="{contactHide : $route.name === 'contact'}">
        <b-row>
          <b-col lg='6' md='12' cols='12'>
            <Twitter :config="config"/>
          </b-col>
          <b-col lg='6' md='12' cols='12'>
            <Instagram :config='config'/>
          </b-col>
        </b-row>
      </div>
  </div>
</template>

<script>
import apiCall from "@/services/api.js";
import Twitter from '@/components/twitterFeed.vue'
import Instagram from '@/components/instaFeed.vue'

export default {
  name: "Footer",
  props: ["white", 'contactF', "config"],
  components:{
    Twitter,
    Instagram
  },
  data() {
    return {
      footW: [],
      footG: [],
      logoImg: "logoImg",
      ocsLogo: "ocsLogo",
      logoRow: "logoRow",
      logoRowMobile: "logoRowMobile",
      hover: false,
    };
  },
  async mounted() {
    var one = "wp/v2/FooterBottom?per_page=25"
    var two = "wp/v2/FooterBottomWhite?per_page=25"
    try {
      // First call, await for call to return
      let first_call = await apiCall.wpInfo(one);
      this.footG = first_call.data;
      this.loading = false;
      // Second call, await for call to return
      let second_call = await apiCall.wpInfo(two);
      this.footW = second_call.data;
      this.loading = false;
    } catch (error) {
      console.log("error in await calls");
      console.log(error);
      throw error;
    }
  }
};
</script>

<style scoped>
.contactHide {
  display: none;
}
.contactMarg{
  margin-bottom:0px!important;
      padding-bottom: 30px;
}
.logoPadding.suppliersLogo.col-md-6.col-lg-3.show {
  padding: 1%;
  display: inline-table;
  margin-left: 25px;
  padding-bottom: 15px;
}
img.maleFooter {
  width: 100%;
}
.white > div > img {
  width: 100%;
}
.col-md-3.logoPadding {
  margin-top: 30px;
  margin-bottom: 30px;
}
.FooterSponsorsSup {
  margin: auto;
  padding-left: 100px;
  padding-right: 100px;
}
.logos {
  /* width: 210px; */
  /* width: 100%; */
  width: 165px;
  mix-blend-mode: multiply;
}
.hide {
  display: none;
}
.show {
  padding: 2%;
  text-align: center;
}
.title {
  padding-left: 4%;
  font-weight: 400;
  margin-bottom: 2em;
  margin-top: 1em;
  text-align: center;
  background-color: #1A2337!important;
}
.grey{
  padding-top: 2%;
  background-color: #F0F0F0;
}
.white{
  padding-top: 2%;
}
.contact{
  padding-top: 12%;
}
#social{
  padding: 50px;
}
.mobile{
  display: none;
}
@media only screen and (max-width: 990px) {
  .show {
    padding: 2%;
    text-align: left;
  }
}
@media only screen and (max-width: 768px) {
  .logos{
    width: 100%;
  }
  .logoPadding.suppliersLogo.col-md-6.col-lg-3.show {
    margin-left: 0;
  }
}
@media only screen and (max-width: 500px) {
  ::v-deep .show {
    padding: 10%!important;
    text-align: left;
  }
  ::v-deep .title {
    padding-left: 4%;
    font-weight: 400;
    margin-bottom: 2em;
    margin-top: 1em;
    text-align: center;
  }
   .desktop{
    display: none;
  }
  .mobile{
    display: block;
  }
  .FooterSponsorsSup {
  margin: auto;
  padding-left: 25px!important;
  padding-right: 25px!important;
}
}
</style>
