var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"menu"},[(_vm.isMobile())?_c('div',[_c('b-modal',{directives:[{name:"show",rawName:"v-show",value:(_vm.show),expression:"show"}],ref:"myModal",staticClass:"modalFull",attrs:{"id":"modal-1","hide-footer":"","hide-header":"","centered":""},on:{"show-me":function($event){_vm.show = true},"hide-me":function($event){_vm.show = false}}},[_c('b-row',{staticClass:"Downloadbox"},[_c('b-col',[_c('img',{staticClass:"dLoadImg",attrs:{"src":"https://ocs-sport.ams3.cdn.digitaloceanspaces.com/tartanprotour/2021/03/FFOODSTPT.png"}}),_c('h2',{staticClass:"dLoad"},[_vm._v(" Download The Tartan Pro Tour App ")])])],1),_c('b-row',[_c('b-col',{staticClass:"downloadLinks",attrs:{"md":"6"}},[_c('a',{attrs:{"href":_vm.config.VUE_APP_GOOGLE_PLAY,"target":"_blank","rel":"noopener"}},[_c('b-img',{staticClass:"appStore",attrs:{"src":"https://ocs-sport.ams3.cdn.digitaloceanspaces.com/tartanprotour/2021/03/Mask-Group-4.png"}})],1)]),_c('b-col',{staticClass:"downloadLinks",attrs:{"md":"6"}},[_c('a',{attrs:{"href":_vm.config.VUE_APP_APPLE_STORE,"target":"_blank","rel":"noopener"}},[_c('b-img',{staticClass:"appStore",attrs:{"src":"https://ocs-sport.ams3.cdn.digitaloceanspaces.com/tartanprotour/2021/03/Mask-Group-5.png"}})],1)])],1)],1)],1):_vm._e(),_c('div',{class:{
      bannerImg:
        this.$route.name === 'home' || this.$route.name === 'contact',
        NonBanner: this.$route.name !== 'home' || this.$route.name !== 'contact',
        contactBanner: this.$route.name == 'contact'
      }},[_c('div',{attrs:{"id":"nav"}},[_c('b-navbar',{attrs:{"type":"dark","toggleable":"lg","id":"topNav"}},[_c('b-navbar-brand',{attrs:{"to":"/"}},[_c('img',{staticClass:"logoImg",class:{
              margB:
                this.$route.name !== 'home' || this.$route.name !== 'contact',
            },attrs:{"src":"https://ocs-sport.ams3.cdn.digitaloceanspaces.com/tartanprotour/2021/03/FFOODSTPT.png"}})]),_c('b-navbar-toggle',{directives:[{name:"b-modal",rawName:"v-b-modal.nav-collapse",modifiers:{"nav-collapse":true}}],staticClass:"ml-auto",attrs:{"type":"dark"}}),_c('b-collapse',{attrs:{"id":"nav-collapse","is-nav":""}},[_c('b-navbar-nav',{staticClass:"ml-auto"},[_c('b-nav-item',{attrs:{"to":"/"}},[_vm._v(" HOME ")]),_c('b-nav-item',{attrs:{"to":"/schedule"}},[_vm._v(" SCHEDULE ")]),_c('b-nav-item',{attrs:{"to":"/oom"}},[_vm._v(" OOM ")]),_c('b-nav-item',{attrs:{"to":"/news"}},[_vm._v(" NEWS ")]),_c('b-nav-item',{attrs:{"to":"/contact-us"}},[_vm._v(" CONTACT US ")]),_c('b-nav-item',{attrs:{"href":'https://twitter.com/' + _vm.config.VUE_APP_TWITTER_NAME}},[_c('font-awesome-icon',{staticClass:"icon",attrs:{"icon":['fab', 'twitter-square']}})],1),_c('b-nav-item',{attrs:{"href":'https://www.instagram.com/' + _vm.config.VUE_APP_INSTA_NAME}},[_c('font-awesome-icon',{staticClass:"icon",attrs:{"icon":['fab', 'instagram-square']}})],1)],1)],1),_c('b-modal',{attrs:{"hide-header":"","hide-footer":"","id":"nav-collapse"}},[_c('b-button',{staticClass:"closeBtn",on:{"click":function($event){return _vm.$bvModal.hide('nav-collapse')}}},[_vm._v("X")]),_c('div',{staticClass:"mobileMenu"},[_c('div',{staticClass:"menuGroup"},[_c('b-nav-item',{attrs:{"to":"/"},on:{"click":function($event){return _vm.$bvModal.hide('nav-collapse')}}},[_vm._v(" HOME ")]),_c('b-nav-item',{attrs:{"to":"/schedule"},on:{"click":function($event){return _vm.$bvModal.hide('nav-collapse')}}},[_vm._v(" SCHEDULE ")]),(this.okfound == 'Y' && this.course !== null)?[_c('b-nav-item',{attrs:{"to":{
                    name: 'reports',
                    query: {
                      url: _vm.currentReport(),
                      id: _vm.season,
                      code: _vm.course,
                      fTour: true,
                      title: _vm.currentTitle(),
                    },
                  }},on:{"click":function($event){return _vm.$bvModal.hide('nav-collapse')}}},[_vm._v(" RESULTS ")])]:_vm._e(),_c('b-nav-item',{attrs:{"to":"/oom"},on:{"click":function($event){return _vm.$bvModal.hide('nav-collapse')}}},[_vm._v(" OOM ")]),_c('b-nav-item',{attrs:{"to":"/news"},on:{"click":function($event){return _vm.$bvModal.hide('nav-collapse')}}},[_vm._v(" NEWS ")]),_c('b-nav-item',{staticClass:"cont",attrs:{"to":"/contact-us"},on:{"click":function($event){return _vm.$bvModal.hide('nav-collapse')}}},[_vm._v(" CONTACT US ")]),_c('b-nav-item',{attrs:{"href":'https://twitter.com/' + _vm.config.VUE_APP_TWITTER_NAME}},[_c('font-awesome-icon',{staticClass:"icon",attrs:{"id":"twit","icon":['fab', 'twitter-square']}})],1),_c('b-nav-item',{attrs:{"href":'https://www.instagram.com/' + _vm.config.VUE_APP_INSTA_NAME}},[_c('font-awesome-icon',{staticClass:"icon fa-gradient",attrs:{"id":"insta","icon":['fab', 'instagram-square']}})],1)],2)])],1)],1)],1),(this.$route.name === 'home')?[_c('div',{staticClass:"bannerTitle"},[_c('h1',[_vm._v(" "+_vm._s(_vm.homeSectionData.first_line_text)+" "),_c('br'),_vm._v(" "+_vm._s(_vm.homeSectionData.second_line_text)+" "),_c('br')]),_c('h1',{staticClass:"homeGolf"},[_vm._v(" "+_vm._s(_vm.homeSectionData.slogan)+" ")]),_c('b-button',{attrs:{"to":_vm.homeSectionData.button_link}},[_vm._v("JOIN TODAY")])],1)]:_vm._e()],2)])
}
var staticRenderFns = []

export { render, staticRenderFns }